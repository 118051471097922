<script>
import * as config from "../../config";

export default {
  props: {
    variantId: {
      type: String,
      required: true,
    },
    articleNumber: {
      type: String,
      required: true,
    },
  },
  computed: {
    requestContext() {
      return {
        channelSystemId: this.$channel.systemId,
        websiteSystemId: this.$channel.website?.systemId,
        culture: this.$channel.locale,
      };
    },
  },
  methods: {
    downloadImages() {
      fetch(
        `${config.litiumBaseUrl}/api/product-media-download/entry/${this.variantId}`,
        {
          method: "GET",
          headers: {
            "litium-request-context": JSON.stringify(this.requestContext),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("href", downloadUrl);
          link.setAttribute("download", this.articleNumber);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        });
    },
  },
};
</script>

<template>
  <div class="product-details-image-downloader" @click="downloadImages()">
    <img
      class="product-details-image-downloader__icon"
      src="../../static/icons/download.svg"
    />
    <div class="product-details-image-downloader__text">
      {{ $globalTexts.pdp__download_images }}
    </div>
  </div>
</template>

<style>
.product-details-image-downloader {
  position: absolute;
  margin-top: 1rem;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  border: 1px solid var(--color-neutrals-100);
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
}

.product-details-image-downloader__text {
  font-weight: 400;
  font-size: 14px;
}
</style>