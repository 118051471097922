<script>
/* eslint-disable no-underscore-dangle */
import { defineComponent } from "vue";

import ProductDescription from "./ProductDescription.vue";
import ProductSpecifications from "./ProductSpecifications.vue";
import ProductCO2Details from "./ProductCO2Details.vue";

export default defineComponent({
  components: {
    ProductDescription,
    ProductSpecifications,
    ProductCO2Details,
  },
  data() {
    const activeTab = 1;
    return { activeTab };
  },
  inject: ["$addToCartContextReactive"],
  computed: {
    tabs() {
      var tabsPerTemplate = this.$channel?.website?.fields?.PdpTabsPerTemplate;
      if(this.product.isGiftcard) {
        return tabsPerTemplate?.find(t => t.TemplateName === "Giftcard")?.PdpTabs;
      }
      return tabsPerTemplate?.find(t => t.TemplateName === "Product")?.PdpTabs;
    },
    product() {
      return this.$addToCartContextReactive.product;
    },
  },
  methods: {
    setCurrentTab(index){
      this.activeTab = index;
    },
    showTab(tab){
      return this.tabs?.includes(tab);
    }
  },
  mounted() {
    this.activeTab = this.product.isGiftcard ? 1 : 2;
  }
});
</script>

<template>
  <div class="product-details">
    <div class="product-details__tabs">
      <button 
        class="product-details__tab-header"
        :class="activeTab === 1 && 'product-details__tab-active-header'"
        @click="setCurrentTab(1)"
        v-if="showTab('description')"
      >
      {{ $globalTexts.pdp__info_description }}
      </button>
            <button 
        class="product-details__tab-header"
        :class="activeTab === 2 && 'product-details__tab-active-header'"
        @click="setCurrentTab(2)"
        v-if="showTab('specification')"
      >
      {{ $globalTexts.pdp__info_specification }}
      </button>
      <button 
        class="product-details__tab-header"
        :class="activeTab === 3 && 'product-details__tab-active-header'"
        @click="setCurrentTab(3)"
        v-if="showTab('co2_footprint')"
      >
        {{ $globalTexts.pdp__info_co2footprint }}
      </button>
    </div>

    <div class="product-details__tab-content">
      <ProductDescription v-if="activeTab === 1"/>
      <ProductSpecifications v-if="activeTab === 2"/>
      <ProductCO2Details v-if="activeTab === 3"/>
    </div>

  </div>
</template>

<style>
.product-details {
  max-width: calc(var(--header-max-width) + 30px);
  padding: 0 10px;
  margin: 0 auto;
}

.product-details__info {
  margin-bottom: 50px;
}

.product-details__tabs {
  position: relative;
  justify-content: space-around;
  display: flex;
  width: 100%;
  margin: 1rem 0;
}

.product-details__tabs::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: var(--color-neutrals-55);
  bottom: 1px;
  right: 0;
  left: 0
}

.product-details__tab-active-header {
  position: relative;
}

.product-details__tab-active-header::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 3px;
  background-color: black;
  bottom: 0px;
  right: 0;
  left: 0;
  z-index: 10;
}

.product-details__tab-header {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 8px;
}

.product-details__tab-content {
  padding: 1rem;
}

@media (--tabletAndDesktop) {
  .product-details {
    flex-direction: row;
    padding: 0 15px;
    margin-bottom: 1rem;
  }

  .product-details__info {
    margin-bottom: 0px;
  }

  .product-details__tab-header {
    min-width: 200px;
  }
}

@media (max-width: 1200px) and (min-width: 769px) {
  .product-details__info {
    max-width: 60%;
  }
}

@media (--tablet) {
  .product-details {
    padding: 0 15px;
  }

  .product-details__info {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
}

@media (--desktop) {
  .product-details {
    padding: 20px 15px 40px;
  }
}
</style>
