<script>
import { defineComponent } from "vue";

export default defineComponent({
    inject: ["$addToCartContextReactive"],
    computed: {
        selectedVariant() {
            return this.$addToCartContextReactive.selectedVariant;
        },
        articleNumber() {
            return this.selectedVariant?.articleNumber;
        },
        product() {
            return this.$addToCartContextReactive.product;
        },
        tableImages(){
            return this.$channel?.website?.fields?.CO2TableImages;
        },
        CO2Materials(){
            return Number(this.selectedVariant?.fields?.LCA_Material_Production_B2B_AX?.value);
        },
        CO2Packaging(){
            return Number(this.selectedVariant?.fields?.LCA_Packaging_B2B_AX?.value);
        },
        CO2Transport(){
            return Number(this.selectedVariant?.fields?.LCA_Transport_B2B_AX?.value);
        },
        CO2Energy(){
            return Number(this.selectedVariant?.fields?.LCA_Electronics_and_Energy_B2B_AX?.value);
        },
        CO2End(){
            return Number(this.selectedVariant?.fields?.LCA_End_of_Life_B2B_AX?.value);
        },
        CO2Total(){
            return Math.round(((this.CO2Materials || 0) + (this.CO2Packaging || 0) + (this.CO2Transport || 0) + (this.CO2Energy || 0) + (this.CO2End || 0)) * 100) / 100;
        },
        CO2TotalFormatted(){
            return Math.round(this.CO2Total / 10) / 100;
        },
        CO2MaterialsPercent(){
            return Math.round(this.CO2Materials / this.CO2Total * 10000) / 100;
        },
        CO2PackagingPercent(){
            return Math.round(this.CO2Packaging / this.CO2Total * 10000) / 100;
        },
        CO2TransportPercent(){
            return Math.round(this.CO2Transport / this.CO2Total * 10000) / 100;
        },
        CO2EnergyPercent(){
            return Math.round(this.CO2Energy / this.CO2Total * 10000) / 100;
        },
        CO2EndPercent(){
            return Math.round(this.CO2End / this.CO2Total * 10000) / 100;
        },
    },
    methods: {
        getImage(name){
            var image = this.tableImages.find(i => i.description === name);
            return this.$toLitiumMediaUrl(image?.id) || null;
        },
        getFieldValueWithFallback(fieldId) {
            let value = this.selectedVariant.fields[fieldId];
            if (value === null || typeof value === "undefined") {
                value = this.product.filter[fieldId];
            }
            return value;
        },
    }
});
</script>

<template>
    <div>
        <h3 class="product-details__co2-table-information-title">
            {{ $globalTexts.pdp__tabs_co2_title }}
        </h3>
        <div class="product-details__co2-table-information-description">
            {{ $globalTexts.pdp__tabs_co2_description }}
        </div>
        <table class="product-details__co2-table">
            <tr class="product-details__co2-table-row">
                <th class="product-details__co2-table-head">
                    {{ $globalTexts.global__product }}
                </th>
                <th class="product-details__co2-table-head">
                    {{ $globalTexts.pdp__tabs_co2_total_kg_co2_per_piece }}
                </th>
                <th class="product-details__co2-table-head product-details__co2-table-combined-head">
                    <div class="product-details__co2-table-combined-head-content">
                        <img 
                        :src="getImage('material')" 
                        loading="lazy"
                        class="product-details__co2-table-header-image" 
                        />
                        {{ $globalTexts.pdp__tabs_co2_materials_title }}
                    </div>
                </th>
                <th class="product-details__co2-table-head product-details__co2-table-combined-head">
                    <div class="product-details__co2-table-combined-head-content">
                        <img 
                            :src="getImage('packaging')" 
                            loading="lazy"
                            class="product-details__co2-table-header-image" 
                            />
                        {{ $globalTexts.pdp__tabs_co2_packaging_title }}
                    </div>
                </th>
                <th class="product-details__co2-table-head product-details__co2-table-combined-head">
                    <div class="product-details__co2-table-combined-head-content">
                        <img 
                            :src="getImage('transport')" 
                            loading="lazy"
                            class="product-details__co2-table-header-image" 
                            />
                        {{ $globalTexts.pdp__tabs_co2_transport_title }}
                    </div>
                </th>
                <th class="product-details__co2-table-head product-details__co2-table-combined-head">
                    <div class="product-details__co2-table-combined-head-content">
                        <img 
                            :src="getImage('energy')" 
                            loading="lazy"
                            class="product-details__co2-table-header-image" 
                            />
                        {{ $globalTexts.pdp__tabs_co2_energy_title }}
                    </div>
                </th>
                <th class="product-details__co2-table-head product-details__co2-table-combined-head">
                    <div class="product-details__co2-table-combined-head-content">
                        <img 
                            :src="getImage('end')" 
                            loading="lazy"
                            class="product-details__co2-table-header-image" 
                            />
                        {{ $globalTexts.pdp__tabs_co2_end_title }}
                    </div>
                </th> 
            </tr>
            <tr class="product-details__co2-table-row">
                <td>{{ articleNumber }}</td>
                <td>{{ isNaN(CO2TotalFormatted) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2TotalFormatted + ' kg' }}</td>
                <td>{{ isNaN(CO2Materials) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2Materials + ' g' }}</td>
                <td>{{ isNaN(CO2Packaging) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2Packaging + ' g' }}</td>
                <td>{{ isNaN(CO2Transport) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2Transport + ' g' }}</td>
                <td>{{ isNaN(CO2Energy) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2Energy + ' g' }}</td>
                <td>{{ isNaN(CO2End) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2End + ' g' }}</td>  
            </tr>
            <tr class="product-details__co2-table-row">
                <td></td>
                <td>100 %</td>
                <td>{{ isNaN(CO2MaterialsPercent) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2MaterialsPercent + ' %' }}</td>
                <td>{{ isNaN(CO2PackagingPercent) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2PackagingPercent + ' %' }}</td>
                <td>{{ isNaN(CO2TransportPercent) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2TransportPercent + ' %' }}</td>
                <td>{{ isNaN(CO2EnergyPercent) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2EnergyPercent + ' %' }}</td>
                <td>{{ isNaN(CO2EndPercent) ? $globalTexts.pdp__tabs_co2_undefined_value : CO2EndPercent + ' %' }}</td>
            </tr>
            <tr class="product-details__co2-table-row">
                <td colspan="7">
                    {{ $globalTexts.pdp__tabs_co2_table_description }}
                </td>
            </tr>
        </table>
        <div class="product-details__co2-table-information-title">
            {{ $globalTexts.pdp__tabs_co2_materials_title }}
        </div>
        <div class="product-details__co2-table-information-description">
            {{ $globalTexts.pdp__tabs_co2_materials_description }}
        </div>
        <div class="product-details__co2-table-information-title">
            {{ $globalTexts.pdp__tabs_co2_packaging_title }}
        </div>
        <div class="product-details__co2-table-information-description">
            {{ $globalTexts.pdp__tabs_co2_packaging_description }}
        </div>
        <div class="product-details__co2-table-information-title">
            {{ $globalTexts.pdp__tabs_co2_transport_title }}
        </div>
        <div class="product-details__co2-table-information-description">
            {{ $globalTexts.pdp__tabs_co2_transport_description }}
        </div>
        <div class="product-details__co2-table-information-title">
            {{ $globalTexts.pdp__tabs_co2_energy_title }}
        </div>
        <div class="product-details__co2-table-information-description">
            {{ $globalTexts.pdp__tabs_co2_energy_description }}
        </div>
        <div class="product-details__co2-table-information-title">
            {{ $globalTexts.pdp__tabs_co2_end_title }}
        </div>
        <div class="product-details__co2-table-information-description">
            {{ $globalTexts.pdp__tabs_co2_end_description }}
        </div>
    </div>
</template>

<style>

.product-details__co2-table-information-title{
    font-weight: bold;
}

.product-details__co2-table-information-description{
    margin-bottom: 1rem;
    line-height: 1.6rem;
}

.product-details__co2-table{
    margin: 2rem 0;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: var(--size-s);
}

.product-details__co2-table th, td {
    border: 1px solid black;
}

.product-details__co2-table > tr > td,
.product-details__co2-table > tr > th {
    padding: 0.5rem 1rem;
}

.product-details__co2-table-head{
    padding: 0.5rem 1rem;
    vertical-align: bottom;
}

.product-details__co2-table-combined-head{
    vertical-align: top;
}

.product-details__co2-table-combined-head-content{
    display: flex;
    flex-direction: column;
}

.product-details__co2-table-header-image{
    width: 50px;
    height: 50px;
    align-self: center;
    margin-bottom: 0.5rem;
}

</style>
